import $ from 'jquery';

(() => {
  $(window).scroll(function () {
    setScroll();
  });

  function setScroll() {
    $('.ko-animFadein').each(function () {
      var position = $(this).offset().top;
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll > position - windowHeight + 100) {
        $(this).addClass('ko-animFadein--active');
      }
    });

    $('.ko-animWipe').each(function () {
      var position = $(this).offset().top;
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll > position - windowHeight + 100) {
        $(this).addClass('ko-animWipe--active');
      }
    });
  }


  setScroll();
})();
