import $ from 'jquery';

(() => {
  const header = $('.ko-header');
  const body = $('body');
  const classNameToggle = 'active';
  const classNameHeader = 'ko-header--active';
  const classNameHidden = 'ko-overflow--hidden';

  $('.ko-header__humItem--trigger').on('click', function () {
    $(this).toggleClass(classNameToggle);
    $(header).toggleClass(classNameHeader);
    $(body).toggleClass(classNameHidden);
    return false;
  });
})();
