import $ from 'jquery';

(() => {
  const target = $('.ko-header__subMenu');
  const targets = target.find('dt');
  const className = 'ko-typeCategory--fc';
  targets.click(function() {
    let parent = $(this).parents('dl');
    parent.toggleClass(className);
    // parent.find('ul').slideToggle(500);
  });
})();
