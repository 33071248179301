// import $ from 'jquery';
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

(() => {
  // First slider
  new Swiper('.ko-3colPhotoBtmTextSlider-1', {
    modules: [Navigation, Pagination, Autoplay],
    slidesPerView: 'auto',
    spaceBetween: 20,
    speed: 700,
    loop: true,
    autoplay: true,
    navigation: {
      nextEl: '.ko-slide__nextBackCtr--next-1',
      prevEl: '.ko-slide__nextBackCtr--back-1',
    },
    breakpoints: {
      769: {
        slidesPerView: 3,
        spaceBetween: 35,
      },
    },
  });

  // Second slider
  new Swiper('.ko-3colPhotoBtmTextSlider-2', {
    modules: [Navigation, Pagination, Autoplay],
    slidesPerView: 'auto',
    spaceBetween: 20,
    speed: 700,
    loop: true,
    autoplay: true,
    navigation: {
      nextEl: '.ko-slide__nextBackCtr--next-2',
      prevEl: '.ko-slide__nextBackCtr--back-2',
    },
    breakpoints: {
      769: {
        slidesPerView: 3,
        spaceBetween: 35,
      },
    },
  });

  // Third slider
  new Swiper('.ko-3colPhotoBtmTextSlider-3', {
    modules: [Navigation, Pagination, Autoplay],
    slidesPerView: 'auto',
    spaceBetween: 20,
    speed: 700,
    loop: true,
    autoplay: true,
    navigation: {
      nextEl: '.ko-slide__nextBackCtr--next-3',
      prevEl: '.ko-slide__nextBackCtr--back-3',
    },
    breakpoints: {
      769: {
        slidesPerView: 3,
        spaceBetween: 35,
      },
    },
  });
})();
