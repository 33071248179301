import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

export default class Slide {
  constructor() {
    // PC top MV
    new Swiper('.ko-topMv__slide1, .ko-topMv__slide3, .ko-topMv__slideSp1', {
      modules: [Navigation, Pagination, Autoplay],
      slidesPerView: 'auto',
      preventInteractionOnTransition: true,
      speed: 6000,
      loop: true,
      direction: 'vertical',
      autoplay: {
        delay: 0,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
    });

    new Swiper('.ko-topMv__slide2, .ko-topMv__slideSp2', {
      modules: [Navigation, Pagination, Autoplay],
      slidesPerView: 'auto',
      preventInteractionOnTransition: true,
      speed: 6000,
      loop: true,
      direction: 'vertical',
      autoplay: {
        delay: 0,
        reverseDirection: true,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
    });
  }
}
// (() => {
//   new SubMenu();
//   new ScrollFix();
// })();
