import $ from 'jquery';

class SubMenu {
  constructor() {
    const left = $('.ko-leftmenu');
    const li = $('.ko-leftmenu__ulLi');
    // const classNameActive = 'ko-leftmenu__ulLi--active';
    // eslint-disable-next-line no-unused-vars
    let type = '';

    li.on('mouseenter', function () {
      // console.log($(this).data('lmenu-type'));
      type = $(this).data('lmenu-type');
      serSubMenu();
    });

    left.on('mouseleave', function () {
      // console.log("マウスが離れた");
      type = '';
      serSubMenu();
    });

    function serSubMenu() {
      left.attr('data-active-type', type);
    }
  }
}

class ScrollFix {
  constructor() {
    const fix = $('.ko-content__right');
    const target = $('.ko-leftmenu');
    const className = 'ko-leftmenu--fix';
    const fixTop = fix.offset().top;
    $(window).scroll(function () {
      if ($(window).scrollTop() >= fixTop) {
        target.addClass(className);
      } else {
        target.removeClass(className);
      }
    });
  }
}

(() => {
  new SubMenu();
  new ScrollFix();
})();
