// import $ from 'jquery';
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

(() => {
  new Swiper('.ko-3colPhotoBtmTextSlider', {
    // configure Swiper to use modules
    modules: [Navigation, Pagination, Autoplay],
    slidesPerView: 'auto',
    spaceBetween: 20,
    speed: 700,
    loop: true,
    autoplay: true,
    navigation: {
      nextEl: '.ko-slide__nextBackCtr--next',
      prevEl: '.ko-slide__nextBackCtr--back',
    },
    breakpoints: {
      769: {
        slidesPerView: 3,
        spaceBetween: 35,
      },
    },
  });
})();
